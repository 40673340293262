<template>
    <div class="relative mx-auto max-w-7xl lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div class="lg:col-span-1">
            <h2 class="text-3xl font-regular text-gray-900 sm:text-4xl">
                Your plan includes
            </h2>
            <p class="text-lg text-gray-500">A reminder about what you’re getting.</p>
        </div>

        <div class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            <ul role="list" class="space-y-4" v-for="group in splitFeatures">
                <li v-for="feature in group" :key="feature" class="flex space-x-3">
                    <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span class="text-md text-gray-500">{{ feature }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import {CheckIcon} from "@heroicons/vue/solid"

const packages = {
    'NjE=': [
        '7 days of JournoLink for free',
        'Up to 5 Clients',
        '1 Press Release',
        '5 team members',
        'Unlimited responses to media requests',
        'Enhanced analytics',
        'Full onboarding and support',
    ],
    'NTM=': [
        'Up to 5 Clients',
        '15 press releases per month',
        '5 team members',
        'Unlimited responses to media requests',
        'Enhanced analytics',
        'Full onboarding and support',
    ],
    'NTg=': [
        'Up to 12 Clients',
        '36 press releases per month',
        '5 team members',
        'Unlimited responses to media requests',
        'Enhanced analytics',
        'Full onboarding and support',
    ],
    'NTk=': [
        'Up to 20 Clients',
        '60 press releases per month',
        '5 team members',
        'Unlimited responses to media requests',
        'Enhanced analytics',
        'Full onboarding and support',
    ],
    'NTA=': [
        '1 Press Release per Month',
        'Send Press Releases to journalists',
        'Unlimited responses to media requests',
        'Guaranteed online publication',
        '1 team member',
        'Analytics',
        'Hosted Press Room',
        'PR calendar',
    ],
    'NTE=': [
        '3 Press Releases per Month',
        'Send Press Releases to journalists',
        'Unlimited responses to media requests',
        'Guaranteed online publication',
        '1 team member',
        'Analytics',
        'Hosted Press Room',
        'PR calendar',
    ],
    'MTMx': [
        'Unlimited Press Releases',
        'Send Press Releases to journalists',
        'Unlimited responses to media requests',
        'Guaranteed online publication',
        '1 team member',
        'Analytics',
        'Hosted Press Room',
        'PR calendar',
    ],
    'MTMy': [
        '4 Press Releases per Month',
        'Send Press Releases to journalists',
        'Unlimited responses to media requests',
        'Guaranteed online publication',
        '2 team members',
        '2 business profiles',
        'Enhanced Analytics',
        'Client Dashboard',
        'Client management CRM',
    ],
    'MTMz': [
        'Unlimited Press Releases',
        'Send Press Releases to journalists',
        'Unlimited responses to media requests',
        'Guaranteed online publication',
        '2 team members',
        '2 business profiles',
        'Enhanced Analytics',
        'Client Dashboard',
        'Client management CRM',
    ],
    'NTI=': [
        'Unlimited responses to media requests',
    ],
}

export default {
    name: "PackageSummary",
    components: {
        CheckIcon,
    },
    props: {
        package: {
            type: String,
            default: '',
        }
    },
    setup(props) {
        const selectedPackage = packages[props.package] ?? []
        const halfSplit = Math.round(selectedPackage.length / 2);
        const splitFeatures = [
            selectedPackage.slice(0, halfSplit),
            selectedPackage.slice(halfSplit, selectedPackage.length)
        ]

        return {
            props,
            packages,
            selectedPackage,
            splitFeatures,
        }
    },
}
</script>

<style scoped>

</style>
