<template>
    <div class="relative pt-6 pb-16">
        <Popover>
            <nav class="relative mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
                <div class="flex items-center flex-1 space-x-10">
                    <div class="flex items-center justify-between w-full lg:w-auto">
                        <a href="/">
                            <span class="sr-only">JournoLink</span>
                            <img class="h-16 w-auto lg:h-10 text-black" :src="props.logoUrl" alt="JournoLink Logo">
                        </a>
                        <div class="-mr-2 flex items-center lg:hidden">
                            <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span class="sr-only">Open main menu</span>
                                <MenuIcon class="h-6 w-6" aria-hidden="true" />
                            </PopoverButton>
                        </div>
                    </div>

                    <PopoverGroup as="nav" class="hidden lg:flex space-x-4 lg:space-x-10">

                        <a href="/pricing" class="font-bold text-gray-500 hover:text-gray-900">Pricing</a>

                        <Popover class="relative" v-slot="{ open }">
                            <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group rounded-md inline-flex items-center text-base font-bold hover:text-gray-900']">
                                <span>Agencies</span>
                                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500 hidden lg:visible lg:inline']" aria-hidden="true" />
                            </PopoverButton>

                            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                    <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div class="relative grid gap-6 bg-white sm:gap-8 sm:p-4">
                                            <a href="/agencies/pr" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                                                <div class="ml-4">
                                                    <p class="text-base font-medium text-gray-900">
                                                        <a href="/agencies/pr" class="font-bold text-gray-500 hover:text-gray-900">PR Agencies</a>
                                                    </p>
                                                </div>
                                            </a>
                                            <a href="/agencies/marketing" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                                                <div class="ml-4">
                                                    <p class="text-base font-medium text-gray-900">
                                                        <a href="/agencies/marketing" class="font-bold text-gray-500 hover:text-gray-900">Marketing Agencies</a>
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </PopoverPanel>
                            </transition>
                        </Popover>

                        <a href="/journalists" class="font-bold text-gray-500 hover:text-gray-900">Journalists</a>

                        <a href="https://resources.journolink.com/partnerships/" class="font-bold text-gray-500 hover:text-gray-900">Partnerships</a>

                        <a href="/resources" class="font-bold text-gray-500 hover:text-gray-900">Resources</a>

                        <a href="/contact" class="font-bold text-gray-500 hover:text-gray-900">Contact</a>
                    </PopoverGroup>
                </div>
                <div class="hidden lg:flex text-right flex items-center ml-4 lg:ml-0 gap-4">
                    <a href="/login" class="lg:px-4 py-2 border border-transparent text-base font-bold rounded-md text-gray-500 bg-white hover:text-gray-900">
                        Sign in
                    </a>
                    <a href="/pricing" class="px-4 py-2 border border-transparent text-base font-bold rounded-md text-white bg-orange-600 hover:bg-orange-700">
                        Sign up
                    </a>
                </div>
            </nav>

            <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
                <PopoverPanel focus class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
                    <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div class="px-5 pt-4 flex items-center justify-between">
                            <div>
                                <img class="h-auto w-1/2 text-black" :src="props.logoUrl" alt="JournoLink Logo">
                            </div>
                            <div class="-mr-2">
                                <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span class="sr-only">Close main menu</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </PopoverButton>
                            </div>
                        </div>
                        <div class="px-2 pt-2 pb-8 space-y-1 text-center">
                            <a href="/pricing" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Pricing</a>

                            <a href="/agencies/pr" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">PR Agencies</a>

                            <a href="/agencies/marketing" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Marketing Agencies</a>

                            <a href="/journalists" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Journalists</a>

                            <a href="https://resources.journolink.com/partnerships/" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Partnerships</a>

                            <a href="/resources" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Resources</a>

                            <a href="/contact" class="block w-full px-5 py-3 rounded-md text-base font-bold text-gray-500 hover:text-gray-900">Contact</a>

                            <a href="/login" class="block w-full px-5 py-3 mt-8 rounded-md text-center font-bold text-gray-500 bg-white hover:bg-gray-100">Sign in</a>

                            <a href="/register" class="block w-full px-5 py-3 rounded-md text-center font-bold text-white bg-orange-600 hover:bg-orange-700">Sign up</a>
                        </div>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel, PopoverGroup } from '@headlessui/vue'
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/vue/outline'

export default {
    name: "MainMenu",
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
        PopoverGroup,
        MenuIcon,
        XIcon,
        ChevronDownIcon
    },
    props: {
        logoUrl: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        return {
            props
        }
    }
}
</script>
