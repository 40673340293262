<template>
    <form method="post" ref="registrationForm" :action="props.action" @submit.prevent="submit">
        <input type="hidden" name="_token" :value="props.csrf">
        <input type="hidden" name="c" :value="time">
        <input type="hidden" name="g-recaptcha-response" :value="recaptchaResponse">

        <div class="max-w-md mx-auto mt-12">
            <div class="pb-2">
                <label for="name" class="sr-only">Name</label>
                <input v-model="state.name" @blur="v$.name.$touch" type="text" name="name" id="name"
                       class="shadow-sm focus:border-gray-800 focus:ring-gray-800 block w-full sm:text-md border-gray-600 rounded-md"
                       :class="{'border-red-600': v$.name.$errors.length}"
                       placeholder="Your Name" />
                <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                    <div class="text-red-500">{{ error.$message }}</div>
                </div>
            </div>
            <div class="py-2 relative">
                <label for="email" class="sr-only">Email</label>
                <input v-model="state.email" @blur="v$.email.$touch" type="email" name="email" id="email"
                       class="shadow-sm focus:border-gray-800 focus:ring-gray-800 block w-full sm:text-md border-gray-600 rounded-md"
                       :class="{'border-red-600': v$.email.$errors.length}"
                       placeholder="Email Address" />
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <RefreshIcon v-if="v$.email.$pending" class="h-5 w-5 text-amber-400 animate-spin" aria-hidden="true" />
                    <CheckCircleIcon v-if="v$.email.$dirty && !v$.email.$pending && v$.email.$errors.length === 0" class="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                    <div class="text-red-500">{{ error.$message }}</div>
                </div>
            </div>
            <div class="py-2">
                <label for="password" class="sr-only">Password</label>
                <input v-model="state.password" @blur="v$.password.$touch" type="password" name="password" id="password"
                       class="shadow-sm focus:border-gray-800 focus:ring-gray-800 block w-full sm:text-md border-gray-600 rounded-md"
                       :class="{'border-red-600': v$.password.$errors.length}"
                       placeholder="Password" />
                <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                    <div class="text-red-500">{{ error.$message }}</div>
                </div>
            </div>
            <div class="py-2" v-if="props.withPhone">
                <label for="telephone" class="sr-only">Telephone</label>
                <input v-model="state.telephone" @blur="v$.telephone.$touch" type="tel" name="telephone" id="telephone"
                       class="shadow-sm focus:border-gray-800 focus:ring-gray-800 block w-full sm:text-md border-gray-600 rounded-md"
                       :class="{'border-red-600': v$.telephone.$errors.length}"
                       placeholder="Telephone" />
                <div class="input-errors" v-for="error of v$.telephone.$errors" :key="error.$uid">
                    <div class="text-red-500">{{ error.$message }}</div>
                </div>
            </div>
            <div class="py-2">
                <button type="submit"
                        class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-white text-center hover:bg-orange-700">
                    Sign up
                </button>
            </div>
        </div>
    </form>
    <recaptcha-modal
        :captcha-showing="readyForSubmission"
        :sitekey="props.sitekey"
        @has-responded="handleRecaptcha"
        @close="closeRecaptcha">
    </recaptcha-modal>
</template>

<script>

import {nextTick, reactive, ref} from "vue"
import useVuelidate from "@vuelidate/core"
import {required, requiredIf, email, helpers} from '@vuelidate/validators'
import {RefreshIcon, CheckCircleIcon} from "@heroicons/vue/solid"
import RecaptchaModal from "./RecaptchaModal"

export default {
    name: "Register",
    components: {
        RefreshIcon,
        CheckCircleIcon,
        RecaptchaModal,
    },
    props: {
        csrf: {
            type: String,
            required: true,
        },
        sitekey: {
            type: String,
            required: true,
        },
        action: {
            type: String,
            default: '/register'
        },
        withPhone: {
            type: Boolean,
            default: true,
        }
    },
    setup(props) {
        const time = parseInt(Date.now() / 1000)

        const recaptchaResponse = ref(null)

        const state = reactive({
            name: '',
            email: '',
            password: '',
            telephone: '',
        })

        const rules = {
            name: { required },
            email: {
                required,
                email: helpers.withMessage('This doesn\'t seem to be a valid email address', email),
                isUnique: helpers.withMessage('This email address appears to already be taken', helpers.withAsync((value) => {
                    if (!value) return true

                    return new Promise((resolve, reject) => {
                        axios.get(base('/register/email-availability'), {
                            params: {
                                email: value,
                            }
                        })
                            .then(response => {
                                resolve(response.data.available)
                            })
                            .catch(e => {
                                reject()
                            })
                    })
                })),
                $lazy: true
            },
            password: { required },
            telephone: { requiredWithPhone: requiredIf(props.withPhone) },
        }

        const readyForSubmission = ref(false)
        const registrationForm = ref(null)
        const v$ = useVuelidate(rules, state, { $lazy: true })

        const submit = async () => {
            const result = await v$.value.$validate()

            if (result) {
                readyForSubmission.value = true
            }
        }

        const closeRecaptcha = () => {
            readyForSubmission.value = false
        }

        const handleRecaptcha = async (response) => {
            recaptchaResponse.value = response

            if (recaptchaResponse.value) {
                await nextTick(() => {
                    registrationForm.value.submit()
                })
            }
        }

        return {
            v$,
            state,
            submit,
            props,
            rules,
            time,
            handleRecaptcha,
            registrationForm,
            readyForSubmission,
            recaptchaResponse,
            closeRecaptcha,
        }
    },
}
</script>

<style scoped>

</style>
