<template>
    <footer class="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-regular text-gray-600">
                                Solutions
                            </h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.solutions" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-900 hover:text-gray-600">
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-regular text-gray-600">
                                Support
                            </h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.support" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-900 hover:text-gray-600">
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-regular text-gray-600">
                                Company
                            </h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.company" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-900 hover:text-gray-600">
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-regular text-gray-600">
                                Legal
                            </h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.legal" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-900 hover:text-gray-600">
                                        {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <lead-subscribe></lead-subscribe>
            </div>

            <div class="border-t-2 border-black mx-auto max-w-xs my-20"></div>

            <div class="md:flex md:items-center md:justify-between">
                <div class="flex space-x-6 md:order-2">
                    <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">{{ item.name }}</span>
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                    </a>
                </div>
                <div class="md:mt-0 md:order-1">
                    <p class="mt-8 text-base text-gray-400">
                        &copy; {{ (new Date()).getFullYear() }} JournoLink Ltd. All rights reserved.
                    </p>
                    <p class="text-xs">
                        JournoLink Ltd is registered in England and Wales, No. 08783164. Registered Office: International House, 10 Churchill Way Cardiff CF10 2HE
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import LeadSubscribe from "./LeadSubscribe"

const navigation = {
    solutions: [
        { name: 'Features', href: '/features' },
        { name: 'PR Agencies', href: '/agencies/pr' },
        { name: 'Marketing Agencies', href: '/agencies/marketing' },
        { name: 'Journalists', href: '/journalists' },
    ],
    support: [
        { name: 'Pricing', href: '/pricing' },
        { name: 'Resources', href: '/resources' },
        { name: 'Case Studies', href: '/success-stories' },
    ],
    company: [
        { name: 'About', href: '/about' },
        { name: 'Press', href: 'https://pressroom.journolink.com/journolink' },
        // { name: 'Partners', href: '/partners' },
    ],
    legal: [
        { name: 'Content Guidelines', href: '/guidelines' },
        { name: 'Privacy', href: '/privacy' },
        { name: 'Terms', href: '/terms' },
    ],
    social: [

    ],
}

export default {
    components: {
        LeadSubscribe
    },
    setup() {
        return {
            navigation,
        }
    },
}
</script>
