<template>
    <div class="bg-gray-100">
        <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
            <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>

            <div class="max-w-2xl mx-auto py-16 px-4 space-y-16 sm:px-6">
                <div v-for="(plan, mobilePlanIndex) in plans" :key="mobilePlanIndex" class="border-t border-gray-200">
                    <div class="-mt-px pt-6 sm:w-1/2 border-transparent">
                        <h3 class="text-gray-900 text-sm font-bold">{{ plan.title }}</h3>
                    </div>

                    <div class="mt-6 relative">
                        <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                            <div class="shadow absolute right-0 w-1/2 h-full rounded-lg" />
                        </div>

                        <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                            <dl class="divide-y divide-gray-200">
                                <div v-for="feature in features" :key="feature.title" class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                                    <dt class="pr-4 text-sm font-medium text-gray-600" v-if="feature.title !== 'CTA'">{{ feature.title }}</dt>
                                    <dd class="flex items-center justify-end sm:px-4 sm:justify-center" v-if="feature.title !== 'CTA'">
                                        <span v-if="typeof feature.tiers[mobilePlanIndex].value === 'string'" class="'text-gray-900 text-sm font-medium" v-html="feature.tiers[mobilePlanIndex].value"></span>
                                        <template v-else>
                                            <CheckIcon v-if="feature.tiers[mobilePlanIndex].value === true" class="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                                            <XIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span class="sr-only">{{ feature.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No' }}</span>
                                        </template>
                                    </dd>
                                    <dd class="w-full sm:px-4 text-center" v-else>
                                        <a :href="feature.tiers[mobilePlanIndex].value" class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700">Select plan</a>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                            <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Feature comparison (lg+) -->
        <section aria-labelledby="comparison-heading" class="hidden lg:block">
            <div class="max-w-7xl mx-auto py-24 px-8">
                <div class="w-full flex items-stretch">
                    <div class="-mt-px w-1/4 pb-6 pr-4 flex items-end">
                        <h2 class="mt-auto text-xl font-light text-gray-800">Feature Comparison</h2>
                    </div>
                    <div v-for="(plan, planIdx) in plans" :key="plan.title" aria-hidden="true" :class="[planIdx === plans.length - 1 ? '' : 'pr-4', '-mt-px pl-4 w-1/4']">
                        <div class="border-transparent pb-6 border-t-2">
                            <p class="text-xl font-light text-gray-800">{{ plan.title }}</p>
                            <p class="text-base font-light text-gray-500">{{ plan.description }}</p>
                        </div>
                    </div>
                </div>

                <div class="relative">
                    <!-- Fake card backgrounds -->
                    <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                        <div class="w-1/4 pr-4" />
                        <div class="w-1/4 px-4">
                            <div class="w-full h-full rounded-lg shadow" />
                        </div>
                        <div class="w-1/4 px-4">
                            <div class="w-full h-full rounded-lg shadow" />
                        </div>
                        <div class="w-1/4 pl-4">
                            <div class="w-full h-full rounded-lg shadow" />
                        </div>
                    </div>

                    <table class="relative w-full">
                        <caption class="sr-only">
                            Business feature comparison
                        </caption>
                        <thead>
                        <tr class="text-left">
                            <th scope="col">
                                <span class="sr-only">Feature</span>
                            </th>
                            <th v-for="plan in plans" :key="plan.title" scope="col">
                                <span class="sr-only">{{ plan.title }} plan</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="feature in features" :key="feature.title">
                                <th scope="row" class="w-1/4 py-3 pc-4 text-left text-md font-light text-gray-600">
                                    <span v-if="feature.title !== 'CTA'">{{ feature.title }}</span>
                                </th>
                                <td v-for="(tier, tierIdx) in feature.tiers" :key="tier.title" :class="[tierIdx === feature.tiers.length - 1 ? 'pl-4' : 'px-4', 'relative w-1/4 py-0 text-center']">
                                    <span class="relative w-full h-full py-3" v-if="feature.title !== 'CTA'">
                                        <span v-if="typeof tier.value === 'string'" class="text-gray-900 text-sm font-medium" v-html="tier.value"></span>
                                        <template v-else>
                                          <CheckIcon v-if="tier.value === true" class="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                                          <XIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                          <span class="sr-only">{{ tier.value === true ? 'Yes' : 'No' }}</span>
                                        </template>
                                    </span>
                                    <div v-if="feature.title === 'CTA'" class="p-2">
                                        <a :href="tier.value" v-if="tier['text']" class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700">{{ tier.text }}</a>
                                        <a :href="tier.value" v-if="!tier['text']" class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700">Select plan</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Fake card borders -->
                    <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                        <div class="w-1/4 pr-4" />
                        <div class="w-1/4 px-4">
                            <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                        </div>
                        <div class="w-1/4 px-4">
                            <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                        </div>
                        <div class="w-1/4 pl-4">
                            <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {CheckIcon, XIcon} from "@heroicons/vue/solid"
import {onMounted, ref, watch} from "vue";

const businessPlans = [
    {
        title: 'Free / Pay as you go',
        description: 'Explore JournoLink for free and only pay for the features you use.',
    },
    {
        title: 'Starter',
        description: 'For small businesses looking for all the tools and a small amount of press activity.',
    },
    {
        title: 'Unlimited',
        description: 'For small/medium businesses looking to increase their reach and be active across the media.',
    },
]

const agencyPlans = [
    {
        title: 'Free / Pay as you go',
        description: 'Explore JournoLink for free and only pay for the features you use.',
    },
    {
        title: 'Starter',
        description: 'For small agencies looking for all the tools to manage their press activity.',
    },
    {
        title: 'Unlimited',
        description: 'For larger/growing agencies looking to increase their reach and be active across the media.',
    },
]

const businessFeatures = [
    {
        title: 'Press Releases per month',
        tiers: [
            { title: 'free', value: '0 included<br /><i>£80 per additional</i>' },
            { title: 'starter', value: '1 included<br /><i>£80 per additional</i>' },
            { title: 'unlimited', value: 'Unlimited' },
        ],
    },
    {
        title: 'Responses to Journalist Requests',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: 'Unlimited' },
            { title: 'unlimited', value: 'Unlimited' },
        ],
    },
    {
        title: 'Guaranteed online publication',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Analytics',
        tiers: [
            { title: 'free', value: true },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Access to the PR Learning Centre',
        tiers: [
            { title: 'free', value: true },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Hosted Press Room',
        tiers: [
            { title: 'free', value: true },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },

        ],
    },
    {
        title: 'PR Calendar',
        tiers: [
            { title: 'free', value: true },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Team members',
        tiers: [
            { title: 'free', value: '1' },
            { title: 'starter', value: '1' },
            { title: 'unlimited', value: '1' },
        ],
    },
    {
        title: 'Price',
        tiers: [
            { title: 'free', value: '£0 per month' },
            { title: 'starter', value: '£59 per month' },
            { title: 'unlimited', value: '£119 per month' },
        ]
    },
    {
        title: 'CTA',
        tiers: [
            { title: 'free', value: '/register', text: 'Explore JournoLink for free' },
            { title: 'starter', value: '/register?package=NTA%3D', text: 'Select plan' },
            { title: 'unlimited', value: '/register?package=MTMx', text: 'Select plan' },
        ]
    }
]

const agencyFeatures = [
    {
        title: 'Press Releases per month',
        tiers: [
            { title: 'free', value: '0 included<br /><i>£80 per additional</i>' },
            { title: 'starter', value: '4 included<br /><i>£80 per additional</i>' },
            { title: 'unlimited', value: 'Unlimited' },
        ],
    },
    {
        title: 'Responses to Journalist Requests',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: 'Unlimited' },
            { title: 'unlimited', value: 'Unlimited' },
        ],
    },
    {
        title: 'Guaranteed online publication',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Enhanced analytics',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Client dashboard',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },

        ],
    },
    {
        title: 'Client management CRM',
        tiers: [
            { title: 'free', value: false },
            { title: 'starter', value: true },
            { title: 'unlimited', value: true },
        ],
    },
    {
        title: 'Team members',
        tiers: [
            { title: 'free', value: '1' },
            { title: 'starter', value: '2 included<br /><i>£10pm per additional</i>' },
            { title: 'unlimited', value: '2 included<br /><i>£10pm per additional</i>' },
        ],
    },
    {
        title: 'Business Profiles',
        tiers: [
            { title: 'free', value: '1' },
            { title: 'starter', value: '2 included<br /><i>£10pm per additional</i>' },
            { title: 'unlimited', value: '2 included<br /><i>£10pm per additional</i>' },
        ],
    },
    {
        title: 'Price',
        tiers: [
            { title: 'free', value: '£0 per month' },
            { title: 'starter', value: '£99 per month' },
            { title: 'unlimited', value: '£149 per month' },
        ]
    },
    {
        title: 'CTA',
        tiers: [
            { title: 'free', value: '/register', text: 'Explore JournoLink for free' },
            { title: 'starter', value: '/register?package=MTMy', text: 'Select plan' },
            { title: 'unlimited', value: '/register?package=MTMz', text: 'Select plan' },
        ]
    }
]

export default {
    name: "BusinessPricingMatrix",
    components: {
        CheckIcon,
        XIcon,
    },
    props: {
        agency: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const features = ref([])
        const plans = ref([])

        onMounted(() => {
            if (props.agency === false) {
                switchBusiness()
            } else {
                switchAgency()
            }
        })

        const switchBusiness = () => {
            plans.value = businessPlans
            features.value = businessFeatures
        }

        const switchAgency = () => {
            plans.value = agencyPlans
            features.value = agencyFeatures
        }

        watch(() => props.agency, () => {
            if (props.agency === false) {
                switchBusiness()
            } else {
                switchAgency()
            }
        })

        return {
            plans,
            features,
        }
    },
}
</script>

<style scoped>

</style>
