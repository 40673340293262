<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="relative bg-white overflow-hidden">
        <div v-if="props.title" class="mx-auto max-w-7xl mb-16">
            <h2 class="text-4xl font-regular text-zinc-800 text-center">{{ props.title }}</h2>
            <div class="border-t-2 border-black mx-auto max-w-xs my-20"></div>
        </div>

        <div class="relative" v-for="(showcase, index) in props.showcases">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div :class="[index % 2 === 0 ? '' : 'lg:col-start-2', 'px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0']">
                    <div>
                        <div class="mt-6 text-center sm:text-left">
                            <h2 class="text-3xl font-regular text-gray-900">
                                {{ showcase.title }}
                            </h2>
                            <p class="mt-4 text-md sm:text-lg text-gray-500">
                                {{ showcase.description }}
                            </p>
                            <div class="inline-flex rounded-md mt-6">
                                <a :href="showcase.button_url" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-md sm:text-lg font-regular rounded-md text-white bg-orange-600 hover:bg-orange-700">
                                    {{ showcase.button_text }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="[index % 2 === 0 ? '' : 'lg:col-start-1', 'mt-12 sm:mt-16 lg:mt-0']">
                    <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <img :data-aos="index % 2 === 0 ? 'fade-left' : 'fade-right'"
                            :class="[index % 2 === 0 ? 'lg:left-0' : 'lg:right-0', 'w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none']"
                             :src="showcase.image"
                             :alt="showcase.title" />
                    </div>
                </div>

            </div>
            <div v-if="index < props.showcases.length - 1" class="border-t-2 border-black mx-auto max-w-xs my-20"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FeatureShowcase",
    props: {
        showcases: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        return {
            props
        }
    }
}
</script>
