<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <section class="py-12 bg-indigo-500 overflow-hidden px-4 sm:px-0 md:py-20 lg:py-24" :class="props.spacingClass">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
            <div class="relative">
                <blockquote>
                    <div class="max-w-3xl mx-auto text-center text-2xl font-regular">
                        <p>"{{ props.quote }}"</p>
                    </div>
                    <footer class="mt-8">
                        <div class="text-center font-regular text-xl">{{ props.attribution }}</div>
                        <div class="mt-8 text-center">
                            <a href="/success-stories" class="mt-8 px-4 py-2 border border-white text-xl font-bold rounded-md text-white">
                                See our case studies
                            </a>
                        </div>
                    </footer>
                </blockquote>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Testimonials",
    props: {
        quote: {
            type: String,
            required: true,
        },
        attribution: {
            type: String,
            required: true,
        },
        spacingClass: {
            type: String,
            default: 'my-20',
        }
    },
    setup(props) {
        return {
            props
        }
    }
}
</script>

<style scoped>

</style>
