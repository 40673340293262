<template>
    <div class="recaptcha">
        <vue-recaptcha :sitekey="sitekey" @verify="verify"></vue-recaptcha>
    </div>
</template>

<script>
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        props: {
            sitekey: {
                type: String
            }
        },
        components: { VueRecaptcha },
        mounted() {
            let script = document.createElement('script')
            script.async = true
            script.defer = true
            script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
            document.querySelector('head').appendChild(script)
        },
        methods: {
            verify (response) {
                this.$emit('verify', response)
            }
        },
    };
</script>

<style>
    .recaptcha div div {
        margin: 0 auto;
    }
</style>
