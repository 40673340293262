<template>
    <div class='VuePagination' :class='props.theme.wrapper'>
        <nav :class='props.theme.nav'>
            <div v-show="props.showPagination" :class="props.theme.list" class="px-4 flex items-center justify-between sm:px-0">
                <div class="-mt-px w-auto flex-1 flex">
                    <a href="#" v-bind="props.prevProps" :class="props.theme.prev" @click.prevent="props.setPrevPage" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </a>
                </div>
                <div class="hidden md:-mt-px md:flex">
                    <a href="#" v-for="page in props.pages" :key="page" :class="props.pageClasses(page)" @click.prevent="props.setPage(page)" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                        <span v-bind="props.aProps" :class="props.theme.link">{{ page }}</span>
                    </a>
                </div>
                <div class="-mt-px w-auto flex-1 flex justify-end">
                    <a href="#" v-bind="props.nextChunkProps" :class="props.theme.next" @click.prevent="props.setNextPage" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        Next
                        <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid'

export default {
    name: 'Pagination',
    props: ['props'],
    components: {
        ArrowNarrowLeftIcon,
        ArrowNarrowRightIcon,
    },
}
</script>

<style scoped>

</style>
