import {createApp, defineAsyncComponent} from "vue"
import AOS from 'aos'

require('./bootstrap');

const app = createApp({
    created() {
        AOS.init()
    }
});

app.component('main-menu', defineAsyncComponent(() => import('./components/MainMenu')))
app.component('page-foot', defineAsyncComponent(() => import('./components/PageFoot')))

app.component('feature-showcase', defineAsyncComponent(() => import('./components/FeatureShowcase')))
app.component('testimonials', defineAsyncComponent(() => import('./components/Testimonials')))

app.component('agency-pricing', defineAsyncComponent(() => import('./components/AgencyPricing')))
app.component('business-pricing', defineAsyncComponent(() => import('./components/BusinessPricing')))
app.component('business-pricing-matrix', defineAsyncComponent(() => import('./components/BusinessPricingMatrix')))
app.component('faqs', defineAsyncComponent(() => import('./components/FAQs')))

app.component('register', defineAsyncComponent(() => import('./components/Register')))
app.component('package-summary', defineAsyncComponent(() => import('./components/PackageSummary')))

app.component('blog-index', defineAsyncComponent(() => import('./components/BlogIndex')))

app.mount('#app')
