<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-7xl mb-16">
            <h2 class="text-4xl font-regular text-zinc-800 text-center">Select a plan</h2>
        </div>
        <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
            <div v-for="tier in tiers" :key="tier.name" class="border border-gray-200 rounded-lg shadow-sm relative">
                <div class="p-6">
                    <h2 class="text-xl leading-6 font-medium text-gray-900">{{ tier.name }}</h2>
                    <p class="leading-6 text-lg text-gray-500">{{ tier.tagline }}</p>
                    <div class="mt-4 h-16">
                        <p v-if="tier.price > 0">
                            <span class="text-6xl font-extrabold text-gray-900">£{{ tier.price }}</span>
                            {{ ' ' }}
                            <span class="text-base font-medium text-gray-500">/mo</span>
                        </p>
                        <p class="text-6xl font-extrabold text-gray-900" v-if="tier.price === 0">Free</p>
                    </div>

                    <div class="p-6 w-full">
                        <a :href="tier.href" class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700">{{ tier.buttonText }}</a>
                    </div>

                    <div class="px-6">
                        <ul role="list" class="space-y-4">
                            <li v-for="feature in tier.features" :key="feature" class="flex space-x-3">
                                <CheckIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                <span class="text-sm text-gray-500">{{ feature }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from "vue"
import {CheckIcon} from "@heroicons/vue/solid"

const tiers = [
    {
        name: '7-day free trial',
        tagline: 'Try JournoLink for 7 days',
        description: 'For small agencies and freelancers trying to make their mark in the PR industry.',
        buttonText: 'Explore JournoLink for free',
        href: '/register?package=NjE%3D',
        price: 0,
        features: [
            'Up to 5 Clients',
            '1 press release',
            '5 team members',
            'Unlimited responses to media requests',
            'Enhanced analytics',
            'Full onboarding and support',
        ],
    },
    {
        name: 'Small',
        tagline: 'For up to 5 clients',
        description: 'For small agencies and freelancers trying to make their mark in the PR industry.',
        buttonText: 'Select plan',
        href: '/register?package=NTM%3D',
        price: 199,
        features: [
            'Up to 5 Clients',
            '15 press releases /mo',
            '5 team members',
            'Unlimited responses to media requests',
            'Enhanced analytics',
            'Full onboarding and support',
        ],
    },
    {
        name: 'Medium',
        tagline: 'For up to 12 clients',
        description: 'For growing agencies with more client needs and bigger plans for PR',
        buttonText: 'Select plan',
        href: '/register?package=NTg%3D',
        price: 289,
        features: [
            'Up to 12 Clients',
            '36 press releases /mo',
            '5 team members',
            'Unlimited responses to media requests',
            'Enhanced analytics',
            'Full onboarding and support',
        ],
    },
    {
        name: 'Large',
        tagline: 'For up to 20 clients',
        description: 'For established agencies with many clients wanting to streamline their operations.',
        buttonText: 'Select plan',
        href: '/register?package=NTk%3D',
        price: 399,
        features: [
            'Up to 20 Clients',
            '60 press releases /mo',
            '5 team members',
            'Unlimited responses to media requests',
            'Enhanced analytics',
            'Full onboarding and support',
        ],
    },
]

export default {
    name: "BusinessPricing",
    components: {
        CheckIcon
    },
    setup() {
        const annually = ref(false);

        const toggleAnnually = () => {
            annually.value = annually.value !== true
        }

        const demoHref = 'https://calendly.com/journolink/30min'

        return {
            demoHref,
            annually,
            toggleAnnually,
            tiers,
        }
    },
}
</script>

<style scoped>

</style>
