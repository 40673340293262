<template>
    <div class="bg-gray-800">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <h2 class="text-3xl font-extrabold text-white">
                Frequently asked questions
            </h2>
            <div class="mt-6 border-t border-gray-400 border-opacity-25 pt-10">
                <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
                    <div v-for="item in props.questions" :key="item.question">
                        <dt class="text-lg leading-6 font-bold text-white">
                            {{ item.question }}
                        </dt>
                        <dd class="mt-2 text-base text-gray-200" v-html="item.answer"></dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FAQs",
    props: {
        questions: {
            type: Array,
            required: true,
        }
    },
    setup(props) {
        return {
            props
        }
    }
}
</script>

<style scoped>

</style>
