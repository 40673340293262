<template>
    <div class="xl:mt-0 xl:pt-0">
        <div class="border-t-2 border-black mx-auto max-w-xs my-20 xl:border-0 xl:my-0"></div>

        <h3 class="text-base font-regular text-gray-600">
            Subscribe
        </h3>
        <p class="mt-4 text-base text-gray-500">
            Find out just how easy JournoLink makes PR.
        </p>
        <form class="mt-4 sm:flex sm:max-w-md" v-if="!submitted" @submit.prevent="showRecaptcha = true">
            <input v-model="email" type="email" name="email-address" id="email-address" autocomplete="email" required="" class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400" placeholder="Enter your email" />
            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" class="w-full bg-gray-800 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Subscribe
                </button>
            </div>
        </form>
        <p class="mt-4 text-base text-green-600" v-if="submitted">Thanks for subscribing, check your emails to verify.</p>

        <recaptcha-modal
            :captcha-showing="showRecaptcha"
            :sitekey="sitekey"
            @has-responded="subscribeToNewsletter"
            @close="closeRecaptcha">
        </recaptcha-modal>
    </div>
</template>

<script>
import { ref } from "vue"
import RecaptchaModal from "./RecaptchaModal"

export default {
    name: "LeadSubscribe",
    components: {
        RecaptchaModal,
    },
    setup() {
        const email = ref(null)
        const loading = ref(false)
        const sitekey = ref(recaptchaKey)
        const submitted = ref(false)
        const showRecaptcha = ref(false)

        const subscribeToNewsletter = async () => {
            loading.value = true
            await axios.post(base('/subscribe'), {
                email: email.value,
            })
                .then((response) => {
                    loading.value = false
                    if (response.status === 200) {
                        showRecaptcha.value = false
                        submitted.value = true
                    }
                })
                .catch((error) => {
                    loading.value = false
                })
        }

        const closeRecaptcha = () => {
            showRecaptcha.value = false
        }

        return {
            email,
            sitekey,
            loading,
            submitted,
            showRecaptcha,
            closeRecaptcha,
            subscribeToNewsletter,
        }
    }
}
</script>

<style scoped>

</style>
