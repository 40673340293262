<template>
    <div>
        <div class="bg-white py-12">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="mx-auto text-center">
                          <span class="relative z-0 inline-flex shadow-sm rounded-md">
                            <button type="button"
                                    class="relative inline-flex items-center px-8 py-2 rounded-l-md border text-sm font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                                    :class="[!isAgency ? 'bg-orange-600 border-orange-600 text-white hover:bg-orange-700' : 'bg-white border-orange-600 text-orange-600 hover:bg-orange-300']"
                                    @click.prevent="switchBusiness">Single-business</button>
                            <button type="button"
                                    class="-ml-px relative inline-flex items-center px-8 py-2 rounded-r-md border text-sm font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                                    :class="[isAgency ? 'bg-orange-600 border-orange-600 text-white hover:bg-orange-700' : 'bg-white border-orange-600 text-orange-600 hover:bg-orange-300']"
                                    @click.prevent="switchAgency">Multi-business</button>
                          </span>
                </div>
                <div
                    class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                    <div v-for="tier in tiers" :key="tier.name"
                         class="border border-gray-200 rounded-lg shadow-sm relative">
                        <div class="p-6">
                            <h2 class="text-xl leading-6 font-medium text-gray-900">
                                {{ tier.name }}
                                <span v-if="tier.offer && tier.offerPricePercentage" class="text-lg text-orange-600 font-light italic">({{ tier.offerPricePercentage }}% Off)</span>
                            </h2>
                            <p class="mt-4 text-base text-gray-500 h-16">{{ tier.description }}</p>
                            <div class="h-16">
                                <p>
                                    <span :class="[tier.offer ? 'line-through text-gray-300 font-medium text-2xl' : 'text-gray-900 font-extrabold text-6xl' ]">£{{ tier.priceMonthly }}</span>
                                    {{ ' ' }}
                                    <span class="text-base font-medium" :class="[tier.offer ? 'line-through text-gray-300' : 'text-gray-500 font-extrabold' ]">/mo</span>
                                    <template v-if="tier.offer">
                                        {{ ' ' }}
                                        <span class="text-6xl font-extrabold text-orange-600">£{{ tier.offerPriceMonthly }}</span>
                                        {{ ' ' }}
                                        <span class="text-base font-medium text-orange-600">/mo</span>
                                    </template>
                                </p>
                            </div>

                            <div class="p-6 w-full">
                                <a v-if="tier.strongButton" :href="tier.href"
                                   class="block w-full bg-orange-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700">{{
                                        tier.buttonText
                                    }}</a>
                                <a v-else :href="tier.href"
                                   class="block w-full bg-white border border-orange-600 rounded-md py-2 text-sm font-semibold text-orange-600 text-center hover:bg-orange-300">{{
                                        tier.buttonText
                                    }}</a>
                            </div>

                            <div class="px-6">
                                <ul role="list" class="space-y-4">
                                    <li v-for="(value, feature) in tier.features" :key="feature" class="flex space-x-3">
                                        <template v-if="typeof value === 'boolean'">
                                            <CheckIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true"
                                                       v-if="value"/>
                                            <XIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"
                                                   v-if="!value"/>
                                            <span :class="[value ? '' : 'line-through', 'text-sm text-gray-500']"
                                                  v-html="feature"></span>
                                        </template>

                                        <template v-else>
                                            <span class="flex-shrink-0 w-5 text-gray-400 text-sm font-bold">{{ value }}</span>
                                            <span class="text-sm text-gray-500">{{ feature }}</span>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BusinessPricingMatrix :agency="isAgency" />
    </div>
</template>

<script>
import {CheckIcon, XIcon} from "@heroicons/vue/solid"
import {onMounted, ref} from "vue";
import BusinessPricingMatrix from "./BusinessPricingMatrix";

const businessTiers = [
    {
        name: 'Free / Pay as you go',
        href: '/register',
        strongButton: true,
        buttonText: 'Create a free account',
        priceMonthly: 0,
        description: 'Explore for free and send press releases whenever you like, without a subscription.',
        features: {
            'Send press releases for £80': true,
            'Respond to unlimited journalist requests': false,
            'Access to the PR Learning Centre': true,
        }
    },
    {
        name: 'Starter',
        href: Date.now() <= (new Date('2023/09/12 18:00:00')).getTime() ?
            '/transaction/checkout/link/view/2c624232cdd221771294dfbb310aca000a0df6ac8b66b696d90ef06fdefb64a3' :
            '/register?package=NTA%3D',
        strongButton: false,
        buttonText: 'Select plan',
        priceMonthly: 59,
        offer: Date.now() <= (new Date('2023/09/12 18:00:00')).getTime(),
        offerPriceMonthly: "44.25",
        offerPricePercentage: 25,
        description: 'Send one press release per month and respond to unlimited journalist requests.',
        features: {
            '1 monthly press release included in plan': true,
            'Respond to unlimited journalist requests': true,
            'Access to the PR Learning Centre': true,
        },
    },
    {
        name: 'Unlimited',
        href: Date.now() <= (new Date('2023/10/23 18:00:00')).getTime() ?
            '/transaction/checkout/link/view/9f14025af0065b30e47e23ebb3b491d39ae8ed17d33739e5ff3827ffb3634953' :
            '/register?package=MTMx',
        strongButton: false,
        buttonText: 'Select plan',
        priceMonthly: 119,
        offer: Date.now() <= (new Date('2023/10/23 18:00:00')).getTime(),
        offerPriceMonthly: "78.54",
        offerPricePercentage: 34,
        description: 'Send unlimited press releases and respond to unlimited journalist requests.',
        features: {
            'Send unlimited press releases': true,
            'Respond to unlimited journalist requests': true,
            'Access to the PR Learning Centre': true,
        },
    },
]

const agencyTiers = [
    {
        name: 'Free / Pay as you go',
        href: '/register',
        strongButton: true,
        buttonText: 'Create a free account',
        priceMonthly: 0,
        description: 'Explore for free and send press releases whenever you like, without a subscription.',
        features: {
            'Send press releases for £80': true,
            'Respond to unlimited journalist requests': false,
            'Business profiles': 1,
            'Team members': 1,
        }
    },
    {
        name: 'Starter',
        href: '/register?package=MTMy',
        strongButton: false,
        buttonText: 'Select plan',
        priceMonthly: 99,
        offer: Date.now() <= (new Date('2023/03/29 18:00:00')).getTime(),
        offerPriceMonthly: "74.25",
        offerPricePercentage: 25,
        description: 'Send four press releases per month and respond to unlimited journalist requests.',
        features: {
            '4 monthly press releases included in plan': true,
            'Respond to unlimited journalist requests': true,
            'Multiple Business profiles': true,
            'Full team management': true,
        }
    },
    {
        name: 'Unlimited',
        href: '/register?package=MTMz',
        strongButton: false,
        buttonText: 'Select plan',
        priceMonthly: 149,
        offer: Date.now() <= (new Date('2023/03/29 18:00:00')).getTime(),
        offerPriceMonthly: "111.75",
        offerPricePercentage: 25,
        description: 'Send unlimited press releases and respond to unlimited journalist requests.',
        features: {
            'Send unlimited press releases': true,
            'Respond to unlimited journalist requests': true,
            'Multiple Business profiles': true,
            'Full team management': true,
        }
    },
]

export default {
    name: "BusinessPricing",
    components: {
        BusinessPricingMatrix,
        CheckIcon,
        XIcon,
    },
    props: {
        agency: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const isAgency = ref(props.agency)

        const tiers = ref([])

        onMounted(() => {
            if (props.agency === false) {
                switchBusiness()
            } else {
                switchAgency()
            }
        })

        const switchBusiness = () => {
            isAgency.value = false
            tiers.value = businessTiers
        }

        const switchAgency = () => {
            isAgency.value = true
            tiers.value = agencyTiers
        }

        return {
            isAgency,
            tiers,
            switchBusiness,
            switchAgency,
        }
    },
}
</script>

<style scoped>

</style>
